import React from 'react'
import './about.css'
import ME from '../../assets/meow.gif'
import {FaAward} from 'react-icons/fa'
import {TbBrandFunimation} from 'react-icons/tb'
import {HiLightBulb} from 'react-icons/hi'

const About = () => {
  return (
    <section id='about' style={{ textAlign: 'center'}}>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <p>Adept at developing and implementing security policies and procedures to protect company data and networks. Proven expertise in
designing and configuring firewalls, intrusion detection systems, and other security systems. Possess strong knowledge of data
privacy laws, security awareness training, and risk management. Well-versed in troubleshooting and resolving complex security-
related issues to drive systems performance. Skilled in directing various technical projects from inception to successful completion
with set deadlines. Articulate communicator possessing excellent problem-solving, analytical, and decision-making skills.
      </p>
      <p>
        Over time I expect this site to host some of my future & past projects along with implementing some neat tools/features. 
      </p>
      
      <br>
      </br>
      {/* this break should be removed once content has been chosen for this placeholder*/}

      
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" /> 
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>3 Years Experience</small>
            </article>
            <article className='about__card'>
              <TbBrandFunimation className='about__icon'/>
              <h5>Hobbys</h5>
              <small>PCB Design, Code Solving, RE & BE</small>
            </article>
            <article className='about__card'>
              <HiLightBulb className='about__icon'/>
              <h5>Interests</h5>
              <small>Python, Linux, Virtualization, Fishing</small>
            </article>        
          </div>

        {/*  <p> 
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore nostrum aperiam fuga asperiores necessitatibus ullam voluptatibus iusto vero modi, deleniti, delectus error dolorum, vel ipsum facere ratione consectetur facilis doloribus?
          </p>  */}
          <br>
          
          </br>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About