import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/meow.gif'
import HeaderSocials from './HeaderSocials'


const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Kingfi5hty</h1>
        <h5 className='text-light'>Technically advanced professional offering extensive experience in design, deployment, and maintenance of secure IT systems.</h5>
        <h5 className='text-light'> "Knowledge is power, but action is the catalyst that transforms it into success."-King</h5>
        <CTA />
        <HeaderSocials />

        <div className='me'>
          <img src={ME} alt="me" />
        </div>


        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header